<template>
  <div class="container">
    <div class="mt-7 pt-3">
      <div>
        <collapse :multiple-active="true">
          <collapse-item v-for="(item, index) in faq_list" :key="index">
            <h5
              slot="title"
              class="mb-0 btn btn-link w-100 text-primary text-left"
            >
              {{ item.question }}
              <i class="fa fa-plus float-right pt-1"></i>
            </h5>
            <div class="text-left" v-html="item.answer"></div>
          </collapse-item>
        </collapse>
      </div>
    </div>
  </div>
</template>
<script>
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
export default {
  components: {
    Collapse,
    CollapseItem
  },
  data: function() {
    return {
      faq_list: []
    };
  },
  metaInfo: {
    title: "Frequently Asked Questions"
  },
  mounted() {
    this.$axios.get("faq/").then(res => {
      this.faq_list = res.data;
    });
  }
};
</script>
<style></style>
